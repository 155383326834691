<template>
  <div class="po-vat">
    <b-form-group :disabled="!editable">
      <label>VAT:</label>
      <b-row>
        <b-col class="pr-0" cols="5">
          <b-form-select
            class="select-style"
            v-model="mainModel.vatType"
            :options="listAmountType"
            size="sm"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </b-col>
        <b-col class="pl-0" cols="7">
          <b-form-input
            class="input-style text-right"
            size="sm"
            type="text"
            v-model="mainModel.vatAmount"
            v-mask="currency"
            placeholder="VAT"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group :disabled="!editable">
      <label>Số hóa đơn VAT:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="mainModel.vatCode"
        placeholder="Số hóa đơn VAT"
      ></b-form-input>
    </b-form-group>
    <b-form-group :label="'Ngày xuất VAT'" :disabled="!editable">
      <date-picker
        placeholder="Chọn ngày xuất VAT"
        class="form-control form-control-sm"
        :config="dpConfigs.date"
        v-model="mainModel.vatDate"
      ></date-picker>
    </b-form-group>
  </div>
</template>

<script>
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import { currencyMask } from './../../../utils/common';
import timeUtils from '@/utils/date';

export default {
  props: ['editable', 'mainModel'],
  data() {
    return {
      currency: currencyMask,
      dpConfigs: timeUtils.DP_CONFIG,
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
    };
  },
  components: {
    datePicker,
  },
};
</script>
